export default class RandomColor {

    constructor( element ) {
        this.element = element;
        this.randomColors = [
            '#F4E4F8',
            '#F7FCBB',
            '#D6E6E4',
            '#B7FFBB',
            '#BBEAFF',
            '#CFC0FF',
            '#EEE6E5'
        ]

        this.setColor();
    }

    setColor() {
        var randomColor = this.randomColors[Math.floor(Math.random() * this.randomColors.length)];
        document.documentElement.style.setProperty("--accent-color", randomColor);
    }
}