export default class Menu {

    constructor( element ) {
        this.element = element;
        
        this.hamburger = document.querySelector('[data-hamburger]');
        this.header = document.querySelector('header');
        this.hamburger.addEventListener( 'click', ( event ) => { this.toggleMenu(); } )
    }

    toggleMenu() {
       
        this.element.classList.toggle('visible');
        this.hamburger.classList.toggle('opened');
        this.header.classList.toggle('menu-open');

    }
    
}