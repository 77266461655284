class Videomutes {

    // constructor() {
    //     this.element;
    // }

    init( element ) {
        this.videomutes = element;
        
        this.videomutes.forEach( ( item ) => {
            item.addEventListener( 'click', ( event ) => { this.toggleMuteVideo(event, item); } )
        });

    }
    
    toggleMuteVideo(event, item) {
        event.stopPropagation();
        event.preventDefault();
        var id = item.dataset.videoMute;
        var target = document.querySelector(`[data-mute-target="${id}"]` );
        target.muted = !target.muted;
    }
}


export const videomutes = new Videomutes();