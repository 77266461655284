import Swiper from 'swiper';
import { Navigation} from "swiper/modules"

class Projectfoldout {

    constructor() {
        this.element;
    }

    init( element ) {
        this.projectlinks = element;
        
        this.projectcloses = document.querySelectorAll( '[data-project-close]' );

        this.projectlinks.forEach( ( item ) => {
            item.addEventListener( 'click', ( event ) => { this.openProject(item); } )
        });

        this.projectcloses.forEach( ( item ) => {
            item.addEventListener( 'click', ( event ) => { this.closeProject(item); } )
        });

    }
    
    openProject(item) {
        var id = item.dataset.projectLink;
        var target = document.querySelector(`[data-project-target="${id}"]` );
        var visibletarget = document.querySelector(`[data-project-target][data-display="visible"]`);
        if (visibletarget) {
            visibletarget.style.setProperty("display", "none");
            visibletarget.dataset.display = 'hidden';
        }

        if (target !== visibletarget) {
            target.style.setProperty("display", "grid");
            target.dataset.display = 'visible';
            this.scrollIntoViewWithOffset(target, 200);  
            this.initSlider(target); 
        }
    }

    closeProject(item) {
        var id = item.dataset.projectClose;
        var target = document.querySelector(`[data-project-target="${id}"]` );
        
        target.style.setProperty("display", "none");
        target.dataset.display = 'hidden';

    }

    initSlider(target) {
        var swiper = target.querySelector('[data-gallery');
        this.gswiper = new Swiper( swiper, {
            modules: [ Navigation ],
            direction: 'horizontal',
            slidesPerView: 1,
            slidesPerGroup: 1,
            releaseOnEdges: true,
            loop: true,
            speed: 1000,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 2,
                }
            }
        } );

        if (window.innerWidth > 1024 ) {
            this.setHeight(swiper, target, 4);
        } else if (window.innerWidth > 768 ) {
            this.setHeight(swiper, target, 2);
        }



    }

    scrollIntoViewWithOffset = (target, offset) => {
        window.scrollTo({
          behavior: 'smooth',
          top:
            target.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        })
      }

      setHeight(swiper, target, slidecount) {
        var height = 0;
        var gridWidth = (window.innerWidth - ((slidecount + 1) * 58)) / slidecount;
        var slides = swiper.querySelectorAll('.swiper-slide img');
        
        slides.forEach( ( slide ) => {
            if (this.getHeight(slide, gridWidth) > height) {
                height = this.getHeight(slide, gridWidth);
            }
        });

        target.style.minHeight = height + 100;
      }

      getHeight(image, gridWidth) {
        const w = image.naturalWidth;
        const h = image.naturalHeight;
    
        let aspectRatio;
    
        aspectRatio = h / w;
    
        return gridWidth * aspectRatio;
    };

}


export const projectfoldout = new Projectfoldout();