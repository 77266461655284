import RandomColor from './components/random-color.js';
import { projectfoldout } from './components/project-foldout.js';
import { videomutes } from './components/video.js';
import Menu from './components/menu.js';



const randomColorElement = document.querySelector('[data-random-color]');
const projectlinks = document.querySelectorAll( '[data-project-link]' );
const videolinks = document.querySelectorAll( '[data-video-mute]' );
const menuElement = document.querySelector('[data-menu]');



if ( randomColorElement ) {
    const randomcolor = new RandomColor (randomColorElement);
}

if ( projectlinks ) {
        projectfoldout.init( projectlinks );
}

if ( videolinks ) {
    videomutes.init( videolinks );
}

if ( menuElement ) {
    const menu = new Menu (menuElement);
}